// no clue why this works... see https://github.com/reactchartjs/react-chartjs-2/issues/1037
import 'chart.js/auto'; 
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
  } from 'chart.js';
import { Bar, Line } from 'react-chartjs-2'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import Button from 'react-bootstrap/Button'
import styled from 'styled-components'
import CodeEditor from '@uiw/react-textarea-code-editor'
import TablePreview from '../components/TablePreview';
import DataSourceSelector from '../components/DataSourceSelector';
import EditorTopbar from '../components/EditorTopbar';
import api from '../lib/api';
import { useAuth } from '../lib/AuthProvider';
import { toVersionString, isVersionGreater } from '../lib/versions';

ChartJS.register(
    CategoryScale,
    LinearScale,
    TimeScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

const FormGroup = styled(Form.Group)`
  padding-bottom: 1em;    
`

const FullHeightRow = styled(Row)`
    height: 100vh;
`

const ErrorMessage = styled.p`
    color: red;
`

const Number = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 5px;
    background-color: #f8f9fa;
    padding: 1em;
    border-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    width: 20em;
    height: 8em;
`

const StyledCodeEditor = styled(CodeEditor)`
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #ced4da;
    shadow: 0 0 5px rgba(0, 0, 0, 0.1)
`

export default function ChartEditor() {
    const { chartKey } = useParams();

    const [version, setVersion] = useState('')
    const [dirty, setDirty] = useState(!version)

    const [editorValue, setEditorValue] = useState('')
    const [dataSourceId, setDataSourceId] = useState('')
    const [dataSource, setDataSource] = useState({})
    const [query, setQuery] = useState('')
    const [queryErrorMessage, setQueryErrorMessage] = useState('')
    const [rows, setRows] = useState([])
    const [data, setData] = useState({})
    const [columns, setColumns] = useState([])
    const [xAxis, setXAxis] = useState('')
    const [yAxis, setYAxis] = useState('')
    const [chartType, setChartType] = useState('Bar') // ['Bar', 'Line']
    const [title, setTitle] = useState('')
    const [showLegend, setShowLegend] = useState(true)
    const [options, setOptions] = useState({})
    const [numberFormat, setNumberFormat] = useState('number')

    const { orgId } = useAuth()

    useEffect(() => {
        if (!chartKey || !orgId) {
            return
        }

        async function getComponent() {
            const { data, error } = await api.listComponents(orgId)

            if (error) {
                console.error(error);
                return
            }

            let latest = null;
            data.forEach((c) => {
                if (c.key === chartKey) {
                    if (!latest || isVersionGreater(c.version, latest.version)) {
                        latest = c;
                    }
                }
            });

            if (!latest) {
                return
            }
            setVersion(latest.version);
            setDirty(false)
            setDataSourceId(latest.data.dataSourceId)
            setEditorValue(latest.data.query)
            setTitle(latest.data.title)
            setChartType(latest.data.type)
            if (latest.data.type === 'Number') {
                setChartType('Number')
                setNumberFormat(latest.data.options.numberFormat)
            } else {
                setXAxis(latest.data.options.xAxis)
                setYAxis(latest.data.options.yAxis)
                setShowLegend(latest.data.options.showLegend)
            }
            runQuery(latest.data.query, latest.data.dataSourceId)
        }
        getComponent();
    }, [chartKey, orgId]);

    useEffect(() => {
        setDirty(true)
    }, [editorValue, dataSourceId, chartType, xAxis, yAxis, title, showLegend, numberFormat])

    function saveEnabled() {
        if (!dirty) {
            return false
        }

        if (dataSourceId === '') {
            return false
        }

        if (query === '' || queryErrorMessage !== '') {
            return false
        }

        if (chartType === 'Number') {
            return true
        }

        if (xAxis === '' || yAxis === '') {
            return false
        }

        return true
    }

    async function createChartVersion() {
        const version = toVersionString(new Date());
        
        const data = {
            dataSourceId: dataSourceId,
            query: query,
            title: title,
            type: chartType,
            options: {},
        }
        if (chartType === 'Number') {
            data.options.numberFormat = numberFormat
        } else {
            data.options.xAxis = xAxis
            data.options.yAxis = yAxis
            data.options.showLegend = showLegend
        }
        
        const resp = await api.createComponentVersion(chartKey, version, 'CHART', data, orgId);
        
        if (resp.error) {
            console.error(resp.error);
            return
        }
        
        setVersion(version)
        setDirty(false)
    }

    function runQuery(query, dataSourceId) {
        setQuery(query)

        if (query === '') {
            console.error('No query to run')
            return
        }

        async function getTableData() {
            const { data, error } = await api.querySql(dataSourceId, query)
            if (error) {
                console.error(error);
                setQueryErrorMessage(error)
                return
            }
            setRows(data.result)
            setColumns(data.result.length > 0 ? Object.keys(data.result[0]) : [])
            setQueryErrorMessage('')
        }
        getTableData()
    }

    useEffect(() => {
        if (rows.length === 0 || xAxis === '' || yAxis === '') {
            return
        }

        setData({
            labels: rows.map(row => row[xAxis]),
            datasets: [
                {
                    label: yAxis,
                    data: rows.map(row => row[yAxis]),
                    backgroundColor: 'rgba(255, 99, 132, 0.2)',
                    borderColor: 'rgba(255, 99, 132, 1)',
                    borderWidth: 1,
                }
            ]
        })
    }, [rows, xAxis, yAxis])

    useEffect(() => {
        const titlePlugin = {}
        if (title) {
            titlePlugin.title = {
                display: true,
                text: title,
            }
        }

        const legendPlugin = {}
        if (!showLegend) {
            legendPlugin.legend = {
                display: false,
            }
        }


        setOptions({
            plugins: {
                ...titlePlugin,
                ...legendPlugin,
            }
        })
    }, [title, showLegend])

    function renderChart() {
        if (!data.labels || data.labels.length === 0) {
            return <p>No data to display</p>
        }

        switch (chartType) {
            case 'Bar':
                return <Bar data={data} options={options} />
            case 'Line':
                return <Line data={data} options={options} />
            default:
                console.error(`Unknown chart type: ${chartType}`)
        }
    }

    function formatNumber(number, format) {
        switch (format) {
          case 'decimal':
            return number.toFixed(2);
          case 'usd':
            return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(number);
          case 'percent':
            return new Intl.NumberFormat('en-US').format((number * 100).toFixed(2)) + '%';
          default:
            return new Intl.NumberFormat('en-US').format(number);
        }
    }   

    function renderNumber() {
        if (rows.length === 0 || columns.length === 0) {
            return <p>No data to display</p>
        }

        // if there is more than one row or more than one columne, we can't display a number
        if (rows.length !== 1 || columns.length !== 1) {
            return <p>Too many rows or columns to display a number</p>
        }

        return (
            <Number>
                <h1>{formatNumber(rows[0][columns[0]], numberFormat)}</h1>
                <p>{title}</p>
            </Number>
        )
    }

    function renderNumberChartSelectors() {
        return (
            <>
                <FormGroup>
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Chart Title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </FormGroup>

                <FormGroup>
                    <Form.Label>Format</Form.Label>
                    <Form.Control
                        as="select"
                        value={numberFormat}
                        onChange={(e) => setNumberFormat(e.target.value)}
                    >
                        <option key="number" value="number">Number</option>
                        <option key="usd" value="usd">USD</option>
                        <option key="percent" value="percent">Percentage</option>
                    </Form.Control>
                </FormGroup>
            </>
        )
    }

    function renderGeneralChartSelectors() {
        return (
            <>
                <FormGroup>
                    <Form.Label>X Axis</Form.Label>
                    <Form.Control
                        as="select"
                        value={xAxis}
                        onChange={(e) => setXAxis(e.target.value)}
                    >
                        <option></option>
                        {columns.map((col) => (
                            <option key={col} value={col}>{col}</option>
                        ))}
                    </Form.Control>
                </FormGroup>

                <FormGroup>
                    <Form.Label>Y Axis</Form.Label>
                    <Form.Control
                        as="select"
                        value={yAxis}
                        onChange={(e) => setYAxis(e.target.value)}
                    >
                        <option></option>
                        {columns.map((col) => (
                            <option key={col} value={col}>{col}</option>
                        ))}
                    </Form.Control>
                </FormGroup>

                <FormGroup>
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Chart Title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </FormGroup>

                <FormGroup>
                    <Form.Check
                        type="checkbox"
                        label="Show Legend"
                        checked={showLegend}
                        onChange={(e) => setShowLegend(e.target.checked)}
                    />
                </FormGroup>
            </>
        )
    }

    if (!orgId) {
        return <></>
    }
      
    return (
        <>
            <EditorTopbar
                componentType="Chart"
                componentKey={chartKey}
                version={version}
                dirty={dirty}
                saveEnabled={saveEnabled()}
                onSave={createChartVersion}
            />
            <Tabs
                defaultActiveKey="query"
                id="chart-editor-tabs"
                className="mb-3"
            >
                <Tab eventKey="query" title="Query">
                    <DataSourceSelector
                        orgId={orgId}
                        dataSourceId={dataSourceId}
                        setDataSource={(dataSource) => {
                            setDataSourceId(dataSource.id)
                            setDataSource(dataSource)
                        }}
                    />
                    <StyledCodeEditor
                        value={editorValue}
                        language="sql"
                        placeholder="SELECT * FROM table"
                        onChange={(e) => setEditorValue(e.target.value)}
                        minHeight={150}
                        data-color-mode='light'
                    />
                    <Button
                        variant="primary"
                        onClick={() => runQuery(editorValue, dataSourceId)}
                        disabled={editorValue === '' || dataSourceId === ''}
                        style={{ marginTop: ".5rem" }} 
                    >Run Query</Button>
                    { queryErrorMessage && <ErrorMessage>{queryErrorMessage}</ErrorMessage> }
                    <TablePreview
                        dataSourceId={dataSourceId}
                        query={query}
                    />
                </Tab>
                <Tab eventKey="chart" title="Chart">
                    <FullHeightRow>
                        <Col sm={2}>
                            <FormGroup>
                                <Form.Label>Chart Type</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={chartType}
                                    onChange={(e) => setChartType(e.target.value)}
                                >
                                    <option key="Bar" value="Bar">Bar</option>
                                    <option key="Line" value="Line">Line</option>
                                    <option key="Number" value="Number">Number</option>
                                </Form.Control>
                            </FormGroup>

                            {chartType === 'Number' ? renderNumberChartSelectors() : renderGeneralChartSelectors()}
                        </Col>
                        <Col>
                            {chartType === 'Number' ? renderNumber() : renderChart()}
                        </Col>
                    </FullHeightRow>
                </Tab>
            </Tabs>
        </>
    )
}