import styled from 'styled-components'

const H1 = styled.h1`
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.3px;
    color: #252733;
    margin-bottom: 1em;
`

const H2 = styled.h2`
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: normal;
    letter-spacing: 0.4px;
    color: #252733;
    margin-bottom: 1em;
`

const H3 = styled.h3`
    color: var(--grayscale-gray, #9FA2B4);
    /* Bold 12 (0.3 px, CAPS) */
    font-size: 12px;
    font-family: Mulish;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.3px;
    text-transform: uppercase;
`

export {
    H1, H2, H3
}