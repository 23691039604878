import { useState, useEffect } from 'react';
import api from '../lib/api'; 
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { H1 } from '../components/Typography';
import styled from 'styled-components';
import { useAuth } from '../lib/AuthProvider';

const FormGroup = styled(Form.Group)`
    padding-bottom: 1em;    
`

const ErrorMessage = styled.div`
    color: red;
`

export default function DataSources() {
    const [dataSources, setDataSources] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedDataSource, setSelectedDataSource] = useState({});
    const [submitting, setSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const { orgId } = useAuth();

    async function listDataSources() {
        if (!orgId) {
            return
        }

        const { data, error } = await api.listDataSources(orgId)

        if (error) {
            console.error(error);
            return
        }

        let dataSources = data.map((ds) => {
            const parsed = fromDbConnectionString(ds.conn_string);
            return {
                ...ds,
                ...parsed,
            }
        });

        setDataSources(dataSources);
    }

    useEffect(() => {
        listDataSources();
    }, [orgId]);

    function fromDbConnectionString(connectionString) {
        const regex = /^(\w+):\/\/(\w+):(\w+)@([\w.-]+):(\d+)\/(\w+)(\?.*)?$/;
        const match = connectionString.match(regex);
        if (!match) {
          return {};
        }
        const [, driver, username, password, host, port, dbname] = match;
        const result = {
          driver,
          username,
          password,
          host,
          port: parseInt(port, 10),
          dbname,
        };
        return result;
    }

    function openEditorModal(dataSource) {
        setSelectedDataSource(dataSource);
        setShowModal(true);
    }

    function onSave() {
        setSubmitting(true);
        async function saveDataSource() {
            const { error } = await api.upsertDataSource(
                selectedDataSource.id,
                selectedDataSource.name,
                selectedDataSource.conn_string,
                orgId
            )

            if (error) {
                console.error(error);
                setErrorMessage(error.message);
                setSubmitting(false);
                return
            }

            setShowModal(false);
            setSubmitting(false);
            listDataSources();
        }
        saveDataSource();
    }

    function onDeleteClick(id) {
        if (!orgId) {
            return
        }

        async function deleteDataSource() {
            const { error } = await api.deleteDataSource(id, orgId)

            if (error) {
                console.error(error);
                return
            }

            setShowModal(false);
            listDataSources();
        }
        deleteDataSource();
    }
    
    if (!orgId) {
        return <></>
    }

    return (
        <>
            <H1>Data Sources</H1>
            <Button variant="primary" onClick={() => openEditorModal({})}>New</Button>
            {dataSources.length > 0 ?
                <Table hover>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Name</th>
                            <th>Driver</th>
                            <th>Host</th>
                            <th>Port</th>
                            <th>Database</th>
                            <th>Username</th>
                            <th>Password</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataSources.map((ds) => (
                            <tr key={ds.id} onClick={() => openEditorModal(ds)}>
                                <td>{ds.id}</td>
                                <td>{ds.name}</td>
                                <td>{ds.driver}</td>
                                <td>{ds.host}</td>
                                <td>{ds.port}</td>
                                <td>{ds.dbname}</td>
                                <td>{ds.username}</td>
                                <td>*****</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            : null}


            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedDataSource.id ? "Edit Data Source" : "Create Data Source"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Form>
                    <FormGroup controlId="formName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter name"
                            value={selectedDataSource.name}
                            onChange={(e) => setSelectedDataSource({ ...selectedDataSource, name: e.target.value })}
                        />
                    </FormGroup>
                    <FormGroup controlId="formDriver">
                        <Form.Label>Conn String</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="driver://user:pass@host:port/dbname"
                            value={selectedDataSource.conn_string}
                            onChange={(e) => setSelectedDataSource({ ...selectedDataSource, conn_string: e.target.value })}
                        />
                    </FormGroup>
                </Form>
                <ErrorMessage>{errorMessage}</ErrorMessage>
                </Modal.Body>
                <Modal.Footer>
                    { !selectedDataSource.id ? null :
                        <Button
                            variant="danger"
                            onClick={() => onDeleteClick(selectedDataSource.id)}
                        >Delete</Button>
                    }
                    <Button variant="secondary" onClick={() => setShowModal(false)}>Cancel</Button>
                    <Button
                        variant="primary"
                        onClick={onSave}
                        disabled={submitting}
                    >Save</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
