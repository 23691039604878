import { useState, useEffect } from 'react'
import Form from 'react-bootstrap/Form'
import api from '../lib/api'
import styled from 'styled-components'

const FormGroup = styled(Form.Group)`
    padding-bottom: 1em;
`

export default function DataSourceSelector({ orgId, dataSourceId, setDataSource }) {
    const [dataSources, setDataSources] = useState([])

    useEffect(() => {
        async function getDataSources() {
            const { data, error } = await api.listDataSources(orgId)
            if (error) {
                console.error(error);
                return
            }
            setDataSources(data)
            data.forEach((ds) => {
                if (ds.id === dataSourceId) {
                    setDataSource(ds)
                }
            })
        } 
        getDataSources()
    }, [])

    return (
        <FormGroup>
            <Form.Label>Data Sources</Form.Label>
            <Form.Control
                as="select"
                value={dataSourceId}
                onChange={e => setDataSource(dataSources.find((ds) => ds.id === parseInt(e.target.value)))}
                disabled={dataSources.length === 0}
            >
                <option></option>
                {dataSources.map((dataSource) => (
                    <option key={dataSource.id} value={dataSource.id}>{dataSource.name}</option>
                ))}
            </Form.Control>
        </FormGroup>
    )
}