import { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import Container from 'react-bootstrap/Container'
import Toast from 'react-bootstrap/Toast'
import ToastContainer from 'react-bootstrap/ToastContainer'
import styled from 'styled-components'
import { getFormByID, submitForm } from '../lib/api';
import { FormComponent } from 'formity-react-sdk'

const StyledContainer = styled(Container)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 600px;
    height: 100vh;
`

export default function FormHoster() {
    const { formVersionId } = useParams();
    const [form, setForm] = useState({});
    const [submitting, setSubmitting] = useState(false);

    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastVariant, setToastVariant] = useState('success');

    useEffect(() => {
        if (!formVersionId) return;

        async function getForm(id) {
            const { data, error } = await getFormByID(id);
            if (error) {
                console.log(error);
                return;
            }

            setForm(data);
        }
        getForm(formVersionId);
    }, [formVersionId]);

    async function submitToFormity(output) {
        if (!output || Object.keys(output).length === 0) {
            console.log('No output to submit')
            return
        }

        setSubmitting(true)
        const { error } = await submitForm(formVersionId, output);
        if (error) {
            console.error(error)
            setSubmitting(false)
            showError()
            return
        }

        setSubmitting(false)
        showSuccess()
    }

    function showSuccess() {
        setToastVariant('success')
        setToastMessage('Form submitted successfully')
        setShowToast(true)
    }

    function showError() {
        setToastVariant('danger')
        setToastMessage('Form submission failed')
        setShowToast(true)
    }

    return (
        <>
            <ToastContainer position="top-end" className="p-3" style={{ zIndex: 1 }}>
                <Toast
                    bg={toastVariant}
                    show={showToast}
                    onClose={() => setShowToast(false)}
                    delay={5000}
                    autohide
                >
                    <Toast.Header>
                        <strong className="me-auto">{toastVariant === 'success' ? 'Success' : 'Error'}</strong>
                    </Toast.Header>
                    <Toast.Body>{toastMessage}</Toast.Body>
                </Toast>
            </ToastContainer>

            <StyledContainer>
                <FormComponent
                    form={form}
                    previewMode={true}
                    onSubmit={submitToFormity}
                    submitting={submitting}
                />
            </StyledContainer>
        </>
    )
}