import { useState, useEffect } from 'react'
import Table from 'react-bootstrap/Table'
import api from '../lib/api'

export default function TablePreview({ dataSourceId, query, selectedColumns }) {
    const [rows, setRows] = useState([])
    const [columns, setColumns] = useState([])

    useEffect(() => {
        if (!dataSourceId || query == "") {
            return
        }

        async function getTableData() {
            const { data, error } = await api.querySql(dataSourceId, query)
            if (error) {
                console.error(error)
                return
            }
            setRows(data.result)
        }
        getTableData()
    }, [dataSourceId, query])

    useEffect(() => {
        if (selectedColumns?.length > 0) {
            setColumns(selectedColumns)
            return
        }

        setColumns(rows.length > 0 ? Object.keys(rows[0]) : [])
    }, [selectedColumns, rows])

    return (
        <>
            <Table hover responsive>
                <thead>
                    <tr>
                        {columns.map((col) => (
                            <th key={col}>{col}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row, i) => (
                        <tr key={i}>
                            {columns.map((col) => (
                                <td key={`${i}-${col}`}>{row[col]}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    )
}