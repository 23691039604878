import styled from "styled-components"
import { ButtonRow, ButtonOutline } from "./Buttons"
import { H1 } from "./Typography"

const Topbar = styled.div`
    margin-bottom: 1em;
`

export default function EditorTopbar({
    componentId,
    componentType,
    componentKey,
    version,
    dirty,
    saveEnabled,
    onSave
}) {
    function getVersionString() {
        if (dirty && version) {
            return `${version} (unsaved)`
        } else if (version) {
            return `${version}`
        } else {
            return "unsaved"
        }
    }

    function openForm() {
        window.open(`/form/${componentId}`, '_blank')
    }
    
    return (
        <Topbar>
            <H1>{`${componentType} Editor`}</H1>
            Key: {`${componentKey}`}<br />Version: {getVersionString()}
            <ButtonRow>
                <ButtonOutline onClick={onSave} disabled={!saveEnabled}>Save New Version</ButtonOutline>
                {componentType === "Form" && <ButtonOutline onClick={openForm}>Open Form</ButtonOutline>}
            </ButtonRow>
        </Topbar>
    )
}