import { useState } from 'react'
import styled from 'styled-components'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'

const StyledModal = styled(Modal)`
    .modal-title {
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 33px;
        letter-spacing: 0.3px;
        color: #252733;
    }
`

export default function SignupModal({ show, handleSignup, handleClose }) {
    const [key, setKey] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [validated, setValidated] = useState(false)

    function onSubmit(e) {
        const form = e.currentTarget;
        e.preventDefault();

        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true);
            return
        }

        setValidated(true);
        handleSignup(key, email, password)
    }

    return(
        <StyledModal show={show} onHide={handleClose}>
            <Form noValidate validated={validated} onSubmit={onSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>Sign up to save your form!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3" controlId="formKey">
                        <Form.Label>Form Key</Form.Label>
                        <Form.Control required type="text" placeholder="form-key" value={key} onChange={(e) => setKey(e.target.value)} />
                        <Form.Control.Feedback type="invalid">Form Key is required</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control required type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        <Form.Control.Feedback type="invalid">Email is required</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control required type="password" pattern=".{8,}" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)}  />
                        <Form.Control.Feedback type="invalid">Password must be at least 6 characters long</Form.Control.Feedback>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" type="submit">
                        Sign Up
                    </Button>
                </Modal.Footer>
            </Form>
        </StyledModal>
    ) 
}