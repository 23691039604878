import styled from "styled-components"

const ShadowBox = styled.div`
    border-radius: 4px;
    border: 0.5px solid var(--grayscale-divider, #DFE0EB);
    background: var(--grayscale-white, #FFF);
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.10);

    margin: 2em 0;
    padding: 1.75em 1.5em;
    height: 100%;
`

export default ShadowBox