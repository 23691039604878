import { useEffect, useState } from 'react';
import api from '../lib/api'; 
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';

export default function FormSubmissions({ orgId, formKey, formVersion }) {
    const [submissions, setSubmissions] = useState([]);
    const [selectedSubmission, setSelectedSubmission] = useState({});

    useEffect(() => {
        if (!orgId || !formKey || !formVersion) {
            return
        }

        async function getSubmissions() {
            const { data, error } = await api.listFormSubmissions(formKey, formVersion, orgId);

            if (error) {
                console.error(error);
                return
            }

            setSubmissions(data);
        }
        getSubmissions();
    }, [formKey, formVersion, orgId]);

    return (
        <>
            <Row>
                <Col>
                    <ListGroup as="ul">
                        {submissions.map((submission) => (
                            <ListGroup.Item
                                as="li"
                                key={submission.id}
                                onClick={() => setSelectedSubmission(submission)}
                                active={selectedSubmission.id === submission.id}
                            >
                                {new Date(submission.created_at).toLocaleString('en-US')}
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Col>
                <Col>
                    <pre>{JSON.stringify(selectedSubmission.data, null, 2)}</pre>
                </Col>
            </Row>
        </>
    )
}