import styled from 'styled-components'
import Button from 'react-bootstrap/Button';

const ButtonRow = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1em;
    gap: 1em;
`

const ButtonOutlineStyled = styled(Button)`
    font-family: 'Mulish';
    font-style: normal;
    border: 1px solid #3751FF;
    border-radius: 8px;
    background-color: rgba(0,0,0,0);
    color: #3751FF;
`

function ButtonOutline({ onClick, disabled, children }) {
  return (
    <ButtonOutlineStyled variant="outline-primary" onClick={onClick} disabled={disabled}>{children}</ButtonOutlineStyled>
  )
}

export {
    ButtonRow,
    ButtonOutline
}