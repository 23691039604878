import styled from 'styled-components'
import { H2 } from './Typography'
import ShadowBox from './ShadowBox'
import { FormComponent } from 'formity-react-sdk'

const FormPreviewStyled = styled(ShadowBox)`
    p {
        color: #000;
        /* Semibold 16 (0.3 px) */
        font-size: 16px;
        font-family: Mulish;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0.3px;
    }
`

export default function FormPreview({ form, onOutputChange }) {
    if (!form?.fields || form.fields.length === 0) {
        return (
            <FormPreviewStyled>
                <H2>Form Preview</H2>
                <p style={{ textAlign: 'center' }}>Add a field in the form builder to get started!</p>
            </FormPreviewStyled>
        )
    }

    return (
        <FormPreviewStyled>
            <H2>Form Preview</H2>
            <FormComponent
                form={form}
                previewMode={true}
                onOutputChange={onOutputChange}
                onSubmit={(form) => console.log(form)}
            />
        </FormPreviewStyled>
    )
}