export function isVersionGreater(version1, version2) {
    return parseVersionString(version1) > parseVersionString(version2);
}

export function parseVersionString(versionString) {
    const versionStringArray = versionString.split('t');
    const dateString = versionStringArray[0].replace(/\./g, '-');
    const timeString = versionStringArray[1].replace(/\./g, ':');
    return Date.parse(`${dateString} ${timeString}`);
}

export function toVersionString(date) {
    return `${date.getFullYear()}.${date.getMonth()+1}.${date.getDate()}t${date.getHours()}.${date.getMinutes()}.${date.getSeconds()}`;
}