import Form from "react-bootstrap/Form"
import styled from 'styled-components'
import { useState, useEffect } from 'react'
import api from '../lib/api'

const FormGroup = styled(Form.Group)`
    padding-bottom: 1em;    
`

export default function TableEditorOptions({
    setDirty,
    dataSources,
    dataSourceId,
    dbConnString,
    setDataSourceId,
    tableName,
    setTableName,
    selectedColumns,
    setSelectedColumns,
}) {
    const [tableNames, setTableNames] = useState([])
    const [columnNames, setColumnNames] = useState([])

    useEffect(() => {
        if (!dataSourceId) {
            return
        }

        async function connectToDB() {
            const { data, error } = await api.showTables(dataSourceId)
            if (error) {
                console.error(error);
                return
            }
            setTableNames(data.result.map(row => (
                row.name ? row.name : row.table_name
            )))
        }
        connectToDB()
    }, [dataSourceId])

    useEffect(() => {
        if (dataSourceId === "" || tableName === "") {
            return
        }

        async function getTableData() {
            const { data, error } = await api.querySql(dataSourceId, `SELECT * FROM ${tableName} LIMIT 1`)
            if (error) {
                console.error(error);
                return
            }
            setColumnNames(Object.keys(data.result[0]))
        }
        getTableData()
    }, [dataSourceId, tableName])

    function onDataSourceChange(e) {
        const dataSourceId = e.target.value
        setDataSourceId(dataSourceId)
        setDirty(true)
    }

    function onTableNameChange(e) {
        setTableName(e.target.value)
        setDirty(true)
    }

    function onCheckboxChange(e) {
        const columnName = e.target.value
        if (e.target.checked) {
            setSelectedColumns([...selectedColumns, columnName])
        } else {
            setSelectedColumns(selectedColumns.filter((col) => col !== columnName))
        }
        setDirty(true);
    }

    return (
        <>
            <Form>
                <FormGroup>
                    <Form.Label>Data Sources</Form.Label>
                    <Form.Control
                        as="select"
                        value={dataSourceId}
                        onChange={onDataSourceChange}
                        disabled={dataSources.length === 0}
                    >
                        <option></option>
                        {dataSources.map((dataSource) => (
                            <option key={dataSource.id} value={dataSource.id}>{dataSource.name}</option>
                        ))}
                    </Form.Control>
                </FormGroup>

                <FormGroup>
                    <Form.Label>Table</Form.Label>
                    <Form.Control
                        as="select"
                        value={tableName}
                        onChange={onTableNameChange}
                        disabled={tableNames.length === 0}
                    >
                        <option></option>
                        {tableNames.map((tableName) => (
                            <option key={tableName}>{tableName}</option>
                        ))}
                    </Form.Control>
                </FormGroup>

                <FormGroup>
                    <Form.Label>Columns</Form.Label>
                    {columnNames.map((columnName) => (
                        <Form.Check
                            key={columnName}
                            type="checkbox"
                            label={columnName}
                            value={columnName}
                            checked={selectedColumns.includes(columnName)}
                            onChange={onCheckboxChange}
                        />
                    ))}
                </FormGroup>
            </Form>
        </>
    )
}