import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Navbar from 'react-bootstrap/Navbar'
import styled from 'styled-components';
import { Outlet } from 'react-router-dom';

const StyledNavbar = styled(Navbar)`
    background-color: #363740;
    border-bottom: 1px solid #e5e5e5;
    box-shadow: 0 2px 4px rgba(0,0,0,.04);
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
`

const NavbarBrand = styled(Navbar.Brand)`
    color: #fff;
    opacity: 0.7;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.3px;
`

const BodyContainer = styled(Container)`
    background-color: #F7F8FC;
`

export default function LayoutPLG() {
    return (
        <>
            <StyledNavbar expand="lg">
                <Container>
                    <NavbarBrand href="/">Formity</NavbarBrand>
                </Container>
            </StyledNavbar>
            <BodyContainer>
                <Row>
                    <Col>
                        <Outlet />
                    </Col>
                </Row>
            </BodyContainer>
        </>
    );
}