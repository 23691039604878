import styled from 'styled-components'
import { H2, H3 } from './Typography'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import { nanoid } from 'nanoid'
import ShadowBox from './ShadowBox'

const FormBuilderStyled = styled(ShadowBox)`
    margin: 2em 0;
    padding: 1.75em 1.5em;
    height: 100%;
`

const UnderlineTabs = styled(Tabs)`
    border-bottom: 2px solid #DFE0EB;

    .nav-item:hover {
        border: none;
    }

    .nav-link {
        color: #000;
        font-size: 12px;
        font-family: Mulish;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.3px;
        text-transform: uppercase;

        transition: none;
    }

    .nav-link:hover {
        border: none;
        transition: none;
    }

    .nav-link.active {
        border: none;
        border-bottom: 2px solid #9FA2B4;
    }
`

const FieldTypeDropdown = styled(Dropdown)`
    margin-top: 1em;

    button {
        border-radius: 8px;
        border: 1px solid var(--grayscale-gray-lightest, #F0F1F7);
        background: var(--grayscale-extra-light, #FCFDFE);

        color: var(--grayscale-gray-dark, #4B506D);
        font-size: 14px;
        font-family: Mulish;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.3px;
    }

    h3 {
        color: var(--grayscale-gray, #9FA2B4);
        /* Bold 12 (0.3 px, CAPS) */
        font-size: 12px;
        font-family: Mulish;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.3px;
        text-transform: uppercase;
    }

    .dropdown-menu {
        border-radius: 8px;
        background: var(--neutral-white, #FFF);
        box-shadow: 0px 4px 16px 0px rgba(43, 43, 67, 0.12);

        color: var(--grayscale-black, #252733);
        font-size: 14px;
        font-family: Mulish;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.1px;
    }
`

const FieldEditor = styled.div`
    margin: 1em 0;
    padding: 1em;

    border-radius: 4px;
    border: 1px solid var(--grayscale-gray-light, #C5C7CD);
    background: var(--grayscale-white, #FFF);
`

const FormEditorLabel = styled(Form.Label)`
    color: var(--grayscale-gray, #9FA2B4);

    /* Bold 12 (0.3 px, CAPS) */
    font-size: 12px;
    font-family: Mulish;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.3px;
    text-transform: uppercase;
`

export default function FormBuilder({ form, setForm, setDirty }) {
    function addField(type) {
        if (!form?.fields) {
            setForm({
                ...form,
                fields: [{
                    key: `${type}-${nanoid(8)}`,
                    type: type,
                    ordinal: 0
                }]
            })
            if (setDirty) {
                setDirty(true)
            }
            return
        }

        setForm({
            ...form,
            fields: [...form.fields, {
                key: `${type}-${nanoid(8)}`,
                type: type,
                ordinal: form.fields.length
            }]
        })
        if (setDirty) {
            setDirty(true)
        }
    }

    function setLabel(fieldKey, label) {
        let index = -1
        form?.fields?.forEach((f, i) => {
            if (f.key === fieldKey) {
                index = i
            }
        })
        if (index < 0) {
            console.error(`Field ${fieldKey} not found`)
            return
        }
        form.fields[index].label = label
        setForm({ ...form })
        if (setDirty) {
            setDirty(true)
        }
    }

    function setOptions(fieldKey, optionsStr) {
        let index = -1
        form?.fields?.forEach((f, i) => {
            if (f.key === fieldKey) {
                index = i
            }
        })
        if (index < 0) {
            console.error(`Field ${fieldKey} not found`)
            return
        }
        form.fields[index].options = optionsStr.split(",").map((option) => {
            return {
                label: option,
                value: option
            }
        })
        setForm({ ...form })
        if (setDirty) {
            setDirty(true)
        }
    }

    function getFormHeaderString(fieldType) {
        // replace the dashes with spaces and convert to Title Case
        return fieldType.replace(/-/g, " ").replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))
    }

    return (
        <FormBuilderStyled>
            <H2>Form Builder</H2>

            <UnderlineTabs>

                <Tab eventKey="fields" title="Fields">
                    <>
                        <>
                            {form?.fields?.map((field, idx) => {
                                switch (field.type) {
                                    case 'short-text':
                                    case 'text-area':
                                        return (
                                            <FieldEditor key={field.key}>
                                                <H3>{`Question ${idx + 1} - ${getFormHeaderString(field.type)}`}</H3>
                                                <Form.Group>
                                                    <FormEditorLabel>Label</FormEditorLabel>
                                                    <Form.Control type="text" value={field.label ?? ''} onChange={e => setLabel(field.key, e.target.value)} />
                                                </Form.Group>
                                            </FieldEditor>
                                        )
                                    case "select-one":
                                    case "select-many":
                                        return (
                                            <FieldEditor key={field.key}>
                                                <H3>{`Question ${idx + 1} - ${getFormHeaderString(field.type)}`}</H3>
                                                <Form.Group>
                                                    <FormEditorLabel>Label</FormEditorLabel>
                                                    <Form.Control type="text" value={field.label ?? ''} onChange={e => setLabel(field.key, e.target.value)} />
                                                </Form.Group>
                                                <Form.Group>
                                                    <FormEditorLabel>Options</FormEditorLabel>
                                                    <Form.Control
                                                        as="textarea"
                                                        rows={2}
                                                        value={field.options?.map(option => option.label).join(",")}
                                                        onChange={e => setOptions(field.key, e.target.value)}
                                                    />
                                                </Form.Group>
                                            </FieldEditor>
                                        )
                                    case 'address-us':
                                        return (
                                            <FieldEditor key={field.key}>
                                                <H3>{`Question ${idx + 1} - Address (US)`}</H3>
                                                <Form.Group>
                                                    <FormEditorLabel>Label</FormEditorLabel>
                                                    <Form.Control type="text" value={field.label ?? ''} onChange={e => setLabel(field.key, e.target.value)} />
                                                </Form.Group>
                                            </FieldEditor>
                                        )
                                    default:
                                        console.error(`Unknown field type: ${field.type}`)
                                }
                            })}
                        </>

                        <FieldTypeDropdown>
                            <h3>Add Field</h3>

                            <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
                                Select Type
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => addField('short-text')}>Short Text</Dropdown.Item>
                                <Dropdown.Item onClick={() => addField('text-area')}>Text Area</Dropdown.Item>
                                <Dropdown.Item onClick={() => addField('select-one')}>Select One</Dropdown.Item>
                                <Dropdown.Item onClick={() => addField('select-many')}>Select Many</Dropdown.Item>
                                <Dropdown.Item onClick={() => addField('address-us')}>Address (US)</Dropdown.Item>
                            </Dropdown.Menu>
                        </FieldTypeDropdown>
                    </>
                </Tab>

                <Tab eventKey="styles" title="Styles" disabled>
                    Styles
                </Tab>

                <Tab eventKey="settings" title="Settings" disabled>
                    Settings
                </Tab>

            </UnderlineTabs>
        </FormBuilderStyled>
    )
}