import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import api from '../lib/api'
import TablePreview from '../components/TablePreview'
import TableEditorOptions from '../components/TableEditorOptions'
import EditorTopbar from '../components/EditorTopbar'
import { useAuth } from '../lib/AuthProvider'
import { toVersionString, isVersionGreater } from "../lib/versions";

export default function TableEditor() {
    const { tableKey } = useParams();

    const [version, setVersion] = useState("")
    const [dirty, setDirty] = useState(!version)
    const [dbConnString, setDbConnString] = useState("")
    const [dataSources, setDataSources] = useState([])
    const [dataSource, setDataSource] = useState({})
    const [tableName, setTableName] = useState("")
    const [selectedColumns, setSelectedColumns] = useState([])

    const { orgId } = useAuth()

    function setDataSourceId(id) {
        const dataSource = dataSources.find((ds) => ds.id === parseInt(id))
        setDataSource(dataSource)
        setDbConnString(dataSource.conn_string)
    }

    useEffect(() => {
        if (!tableKey || dataSources.length === 0 || !orgId) {
            return
        }

        async function getTableComponent() {
            const { data, error } = await api.listTableComponents(orgId)
            if (error) {
                console.error(error);
                return
            }

            // find the latest version of the component with this table key
            let latestTable = null;
            data.forEach((table) => {
                if (table.key === tableKey) {
                    if (!latestTable || isVersionGreater(table.version, latestTable.version)) {
                        latestTable = table;
                    }
                }
            });
            if (!latestTable) {
                setVersion("")
                setDataSource({})
                setDbConnString("")
                setTableName("")
                setSelectedColumns([])
                setDirty(false)
                return
            }

            setVersion(latestTable.version)
            setDataSourceId(latestTable.data_source_id)
            setTableName(latestTable.query.split(/\bfrom\b/i)[1].split(/\s+|;/)[1])
            setSelectedColumns(latestTable.selected_cols)
            setDirty(false)
        }
        getTableComponent()
    }, [tableKey, dataSources, orgId])

    useEffect(() => {
        if (!orgId) {
            return
        }
        
        async function getDataSources() {
            const { data, error } = await api.listDataSources(orgId)
            if (error) {
                console.error(error);
                return
            }
            setDataSources(data)
        }
        getDataSources()
    }, [orgId])

    useEffect(() => {
        setSelectedColumns([])
    }, [tableName])

    async function createTableVersion() {
        const version = toVersionString(new Date());

        const { error } = await api.createTableVersion(
            tableKey,
            version,
            dataSource.id,
            `SELECT * FROM ${tableName} LIMIT 10`,
            selectedColumns,
            orgId
        );
        if (error) {
            console.error(error);
            return
        }
        
        setVersion(version);
        setDirty(false);
    }

    return (
        <>
            <EditorTopbar
                componentType="Table"
                componentKey={tableKey}
                version={version}
                dirty={dirty}
                saveEnabled={dbConnString && tableName && selectedColumns?.length > 0}
                onSave={createTableVersion}
            />
            <Row>
                <Col sm={3}>
                    <TableEditorOptions
                        setDirty={setDirty}
                        dataSources={dataSources}
                        dataSourceId={dataSource.id}
                        dbConnString={dbConnString}
                        setDataSourceId={setDataSourceId}
                        tableName={tableName}
                        setTableName={setTableName}
                        selectedColumns={selectedColumns}
                        setSelectedColumns={setSelectedColumns}
                    />
                </Col>
                <Col>
                    <h2>Preview</h2>
                    <TablePreview
                        dataSourceId={dataSource.id}
                        query={`SELECT * FROM ${tableName} LIMIT 10`}
                        selectedColumns={selectedColumns}
                    />
                </Col>
            </Row>
        </>
    )
}