import Table from 'react-bootstrap/Table'
import Dropdown from 'react-bootstrap/Dropdown';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useEffect, useState } from 'react';
import api from '../lib/api';
import { useNavigate } from 'react-router-dom';
import { H1 } from '../components/Typography';
import styled from 'styled-components';
import { useAuth } from '../lib/AuthProvider';
import { isVersionGreater } from "../lib/versions";

const StyledDropdown = styled(Dropdown)`
  margin-bottom: 1rem;
  float: right;
`

const DropdownToggle = styled(Dropdown.Toggle)`
  border: 1px solid #3751FF;
  border-radius: 8px;
  background-color: rgba(0,0,0,0);
  color: #3751FF;
`

export default function Components() {
  const navigate = useNavigate();
  const [components, setComponents] = useState([]);

  const [newComponentKey, setnewComponentKey] = useState('');
  const [newComponentType, setnewComponentType] = useState('FORM'); // ['FORM', 'TABLE']
  const [showModal, setShowModal] = useState(false);

  const { orgId } = useAuth();

  function showNewComponentModal(type) {
    setnewComponentType(type);
    setShowModal(true);
  }

  function onComponentCreate() {
    setShowModal(false);
    switch (newComponentType) {
      case 'FORM':
        navigate(`/forms/${newComponentKey}`);
        break;
      case 'TABLE':
        navigate(`/tables/${newComponentKey}`);
        break;
      case 'CHART':
        navigate(`/charts/${newComponentKey}`);
        break;
      default:
        console.error(`Unknown component type: ${newComponentType}`);
    }
  }

  function toTitleCase(str) {
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  useEffect(() => {
    if (!orgId) {
      return
    }

    async function listComponents() {
      const { data, error } = await api.listComponents(orgId)

      if (error) {
        console.error(error);
        return
      }

      let componentsByKey = {};
      data.forEach((component) => {
        if (componentsByKey[component.key]) {
          if (isVersionGreater(component.version, componentsByKey[component.key].version)) {
            componentsByKey[component.key] = component;
          }
        } else {
          componentsByKey[component.key] = component;
        }
      });

      const { data: tableData, error: tableError } = await api.listTableComponents(orgId);
      if (tableError) {
        console.error(tableError);
        return
      }

      let tablesByKey = {};
      tableData.forEach((component) => {
        if (tablesByKey[component.key]) {
          if (isVersionGreater(component.version, tablesByKey[component.key].version)) {
            tablesByKey[component.key] = { ...component, type: 'TABLE'};
          }
        } else {
          tablesByKey[component.key] = { ...component, type: 'TABLE'};
        }
      });

      const { data: formData, error: formError } = await api.listFormComponents(orgId);
      if (formError) {
        console.error(formError);
        return
      }

      let formsByKey = {};
      formData.forEach((component) => {
        if (formsByKey[component.key]) {
          if (isVersionGreater(component.version, formsByKey[component.key].version)) {
            formsByKey[component.key] = { ...component, type: 'FORM'};
          }
        } else {
          formsByKey[component.key] = { ...component, type: 'FORM'};
        }
      });

      setComponents([
        ...Object.values(componentsByKey),
        ...Object.values(tablesByKey),
        ...Object.values(formsByKey),
      ]);
    }
    listComponents();
  }, [orgId]);

  function onRowClick(component) {
    switch (component.type) {
      case 'FORM':
        navigate(`/forms/${component.key}`);
        break;
      case 'TABLE':
        navigate(`/tables/${component.key}`);
        break;
      case 'CHART':
        navigate(`/charts/${component.key}`);
        break;
      default:
        console.error(`Unknown component type: ${component.type}`);
    }
  }

  return (
    <>
        <H1>Components</H1>
        <StyledDropdown>
            <DropdownToggle id="dropdown-basic">
              Add Component
            </DropdownToggle>

            <Dropdown.Menu>
                <Dropdown.Item onClick={() => showNewComponentModal('FORM')}>Form</Dropdown.Item>
                <Dropdown.Item onClick={() => showNewComponentModal('TABLE')}>Table</Dropdown.Item>
                <Dropdown.Item onClick={() => showNewComponentModal('CHART')}>Chart</Dropdown.Item>
            </Dropdown.Menu>
        </StyledDropdown>
        {components?.length > 0 ?
        <Table hover>
            <thead>
            <tr>
                <th>Key</th>
                <th>Latest Version</th>
                <th>Type</th>
            </tr>
            </thead>
            <tbody>
            {components.map((component) => (
                <tr key={component.key}>
                    <td onClick={() => onRowClick(component)}>{component.key}</td>
                    <td>{component.version}</td>
                    <td>{component.type}</td>
                </tr>
            ))}
            </tbody>
        </Table>
        : null}

        <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{`Create New ${toTitleCase(newComponentType)}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Control
                type="text"
                placeholder={`${newComponentType.toLowerCase()}-key`}
                onChange={e => setnewComponentKey(e.target.value)}
              />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => setShowModal(false)}>Cancel</Button>
                <Button onClick={onComponentCreate}>Create</Button>
            </Modal.Footer>
        </Modal>
    </>
  );
}