import { useAuth } from "../lib/AuthProvider"
import { Auth } from '@supabase/auth-ui-react'
import { ThemeSupa } from "@supabase/auth-ui-shared";
import { supabaseClient } from "../lib/api";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";

const StyledContainer = styled.div`
    max-width: 400px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
`

const H1 = styled.h1`
    text-align: center;
`

export default function Login() {
    const location = useLocation();
    const redirect = new URLSearchParams(location.search).get('redirect');

    const { session } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (session) {
            if (redirect) {
                navigate(redirect)
            } else {
                navigate('/')
            }
        }
    }, [session])

    return (
        <StyledContainer>
            <H1>Formity</H1>
            <Auth
                supabaseClient={supabaseClient()}
                appearance={{ theme: ThemeSupa }}
                providers={[]}
                magicLink
            />
        </StyledContainer>
    )
}