import { useState, useEffect } from 'react'
import { H1 } from '../components/Typography'
import { ButtonRow, ButtonOutline } from '../components/Buttons'
import FormBuilder from '../components/FormBuilder'
import SignupModal from '../components/SignupModal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useNavigate } from 'react-router-dom';

import FormPreview from '../components/FormPreview'
import { useAuth } from '../lib/AuthProvider'
import api from '../lib/api'

export default function FormEditorPLG() {
    const [form, setForm] = useState({})
    const [showSignupModal, setShowSignupModal] = useState(false)
    const { login } = useAuth()
    const navigate = useNavigate();

    async function signUpUser(email, password) {
        const response = await fetch('/api/signupUser', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email, password })
        })

        if (!response.ok) {
            return { error: response.message }
        }
        
        return login(email, password)
    }

    async function createFormVersion(key, fields, orgId) {
        const date = new Date();
        const version = `${date.getFullYear()}.${date.getMonth()}.${date.getDate()}t${date.getHours()}.${date.getMinutes()}.${date.getSeconds()}`;
        const resp = await api.createFormVersion(key, version, fields, orgId);
        
        if (resp.error) {
            return { error: resp.error }
        }
        
        return { data: { key, version, fields } }
    }

    async function onSignupSubmit(key, email, password) {
        setForm({ ...form, key })
        let { data, error } = await signUpUser(email, password)
        if (error) {
            console.error(error);
            return
        }
        const userId = data.user.id;

        ({ data, error } = await api.getOrgId(userId))
        if (error) {
            console.error(error);
            return
        }
        const orgId = data.org_id;

        ({ data, error } = await createFormVersion(key, form.fields, orgId))
        if (error) {
            console.error(error);
            return
        }
        
        navigate(`/forms/${data.key}`);
    }

    return (
        <>
            <H1>Create Your New Form</H1>
            <ButtonRow>
                <ButtonOutline
                    onClick={() => setShowSignupModal(true)}
                    disabled={!form?.fields?.length}
                >Save</ButtonOutline>
                {/* <ButtonOutline>Embed</ButtonOutline>
                <ButtonOutline>Create Shareable Link</ButtonOutline> */}
            </ButtonRow>
            <Row>
                <Col xs={4}>
                    <FormBuilder
                        form={form}
                        setForm={setForm}
                    />
                </Col>
                <Col>
                    <FormPreview form={form} />
                </Col>
            </Row>

            <SignupModal
                show={showSignupModal}
                handleSignup={onSignupSubmit}
                handleClose={() => setShowSignupModal(false)}
            />
        </>
    )
}