import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useAuth } from "../lib/AuthProvider"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Stack from 'react-bootstrap/Stack'
import api from '../lib/api';
import EditorTopbar from "../components/EditorTopbar";
import FormBuilder from "../components/FormBuilder";
import FormPreview from '../components/FormPreview'
import styled from "styled-components";
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import FormSubmissions from "../components/FormSubmissions";
import { toVersionString, isVersionGreater } from "../lib/versions";
import ShadowBox from "../components/ShadowBox";

const StyledRow = styled(Row)`
    margin-bottom: 2rem;
`

const H2 = styled.h2`
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 0.3px;
    color: #252733;
`

export default function FormEditor() {
    const { formKey } = useParams();

    const [form, setForm] = useState({});
    const [fields, setFields] = useState([]);
    const [dirty, setDirty] = useState(!form?.version);
    const [output, setOutput] = useState({});

    const { orgId } = useAuth();

    async function createFormVersion(key, fields) {
        const version = toVersionString(new Date());
        const resp = await api.createFormVersion(key, version, fields, orgId);

        if (resp.error) {
            console.error(resp.error);
            return
        }

        setForm({ key, version });
        setDirty(false);
    }

    useEffect(() => {
        if (!formKey || !orgId) {
            return
        }

        async function getForm() {
            const { data, error } = await api.getFormVersion(formKey, null, orgId)
            if (error) {
                console.error(error);
                return
            }

            if (!data) {
                setForm({ key: formKey });
                setDirty(true);
                return
            }
            setForm(data);
            setFields(data.fields);
            setDirty(false);
        }
        getForm();
    }, [formKey, orgId]);

    if (!form || !orgId) {
        return null;
    }

    return (
        <>
            <EditorTopbar
                componentId={form.id}
                componentType="Form"
                componentKey={form.key}
                version={form.version}
                dirty={dirty}
                saveEnabled={dirty}
                onSave={() => createFormVersion(form.key, fields)}
            />
            <Tabs defaultActiveKey="build" id="form-editor-tabls" className="mb-3">
                <Tab eventKey="build" title="Build">
                    <StyledRow>
                        <Col>
                            <FormBuilder
                                form={{
                                    key: form.key,
                                    version: form.version,
                                    fields: fields
                                }}
                                setForm={(form) => {
                                    setForm(form);
                                    setFields(form.fields);
                                }}
                                setDirty={setDirty}
                            />
                        </Col>
                        <Col>
                            <Stack gap={3}>
                                <FormPreview
                                    form={{
                                        key: form.key,
                                        version: form.version,
                                        fields: fields
                                    }}
                                    onOutputChange={setOutput}
                                />
                                <ShadowBox style={{ margin: '0 0'}}>
                                    <H2>Output</H2>
                                    <pre>{JSON.stringify(output, null, 2)}</pre>
                                </ShadowBox>
                            </Stack>
                        </Col>
                    </StyledRow>
                </Tab>
                <Tab eventKey="submissions" title="Submissions">
                    <FormSubmissions orgId={orgId} formKey={form.key} formVersion={form.version} />
                </Tab>
            </Tabs>
        </>
    );
}