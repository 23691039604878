import { createContext, useContext, useState, useEffect } from "react";
import { supabaseClient, getOrgId } from "./api";

const AuthContext = createContext();

function AuthProvider({ children }) {
    const supabase = supabaseClient();

    const [session, setSession] = useState(null);
    const [userId, setUserId] = useState(null);
    const [orgId, setOrgId] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        supabase.auth.getSession().then(({ data: { session } }) => {
            setSession(session)
            setUserId(session?.user?.id)
            setLoading(false)
        })

        const {
            data: { subscription },
        } = supabase.auth.onAuthStateChange((_event, session) => {
            setSession(session)
            setUserId(session?.user?.id)
            setLoading(false)
        })

        return () => subscription.unsubscribe()
    }, [])

    useEffect(() => {
        if (!userId) {
            return
        }
        setLoading(true)

        async function getOrgIdAsync() {
            const { data, error } = await getOrgId(userId)
            if (error) {
                console.error(error);
                return
            }
            setOrgId(data.org_id)
            setLoading(false)
        }
        getOrgIdAsync();
    }, [userId])

    async function login(email, password) {
        return await supabase.auth.signInWithPassword({ email, password })
    }

    async function logout() {
        await supabase.auth.signOut();
    }

    return (
        <AuthContext.Provider value={{ session, login, logout, orgId }}>
            {!loading && children}
        </AuthContext.Provider>
    )
}

function useAuth() {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('withAuth must be used within an AuthProvider');
    }
    return context;
}

export default AuthProvider;
export { useAuth };