import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Layout from './layout';
import Components from './containers/Components'
import DataSources from './containers/DataSources';
import FormEditor from './containers/FormEditor';
import TableEditor from './containers/TableEditor';
import ChartEditor from './containers/ChartEditor';
import FormHoster from './containers/FormHoster';
import AuthProvider from './lib/AuthProvider';
import Login from './containers/Login';
import Home from './containers/Home';
import FormEditorPLG from './containers/FormEditorPLG';
import LayoutPLG from './layoutPLG';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/form/:formVersionId",
    element: <FormHoster />,
  },
  {
    element: <LayoutPLG />,
    children: [
      {
        path: "/getstarted",
        element: <FormEditorPLG />,
      }
    ]
  },
  {
    element: <Layout />,
    children: [
      {
        path: "/components",
        element: <Components />,
      },
      {
        path: "/datasources",
        element: <DataSources />,
      },
      {
        path: "/forms/:formKey",
        element: <FormEditor />,
      },
      {
        path: "/tables/:tableKey",
        element: <TableEditor />,
      },
      {
        path: "/charts/:chartKey",
        element: <ChartEditor />,
      },
    ]
  },
  {
    path: "/login",
    element: <Login />,
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
