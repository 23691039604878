import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Navbar from 'react-bootstrap/Navbar'
import Nav from "react-bootstrap/Nav";
import styled from 'styled-components';
import { Outlet } from 'react-router-dom';
import { useAuth } from './lib/AuthProvider';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const StyledNavbar = styled(Navbar)`
    background-color: #363740;
    border-bottom: 1px solid #e5e5e5;
    box-shadow: 0 2px 4px rgba(0,0,0,.04);
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
`

const NavbarBrand = styled(Navbar.Brand)`
    color: #fff;
    opacity: 0.7;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.3px;
`

const NavLink = styled(Nav.Link)`
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #A4A6B3;
`

const NavbarText = styled(Navbar.Text)`
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #A4A6B3;
`

const BodyContainer = styled(Container)`
    background-color: #F7F8FC;
`

export default function Layout() {
    const { session, logout } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!session) {
            navigate(`/login?redirect=${location.pathname}`);
        }
    }, [])

    async function onLogoutClick() {
        await logout();
        navigate('/login');
    }

    return (
        <>
            <StyledNavbar expand="lg">
                <Container>
                    <NavbarBrand href="/">Formity</NavbarBrand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav>
                            <NavLink href="/">Components</NavLink>
                            <NavLink href="/datasources">Data Sources</NavLink>
                        </Nav>
                    </Navbar.Collapse>

                    <Nav>
                        <NavbarText>{session?.user?.email}</NavbarText>
                        <NavLink onClick={onLogoutClick}>Logout</NavLink>
                    </Nav>
                </Container>
            </StyledNavbar>
            <BodyContainer>
                <Row>
                    <Col>
                        <Outlet />
                    </Col>
                </Row>
            </BodyContainer>
        </>
    );
}