import { createClient } from "@supabase/supabase-js";

const supabase = createClient(
    process.env.REACT_APP_SUPABASE_URL,
    process.env.REACT_APP_SUPABASE_KEY
);

export function supabaseClient() {
    return supabase;
}

export function listDataSources(orgId) {
    return supabase
        .from('data_sources')
        .select('*')
        .eq('org_id', orgId)
        .order('created_at')
}

export function upsertDataSource(id, name, connString, orgId) {
    if (!id) {
        return supabase
            .from('data_sources')
            .insert([
                {
                    name: name,
                    conn_string: connString,
                    org_id: orgId,
                }
            ]);
    }

    return supabase
        .from('data_sources')
        .upsert([
            {
                id: id,
                name: name,
                conn_string: connString,
                org_id: orgId,
            }
        ]);
}

export function deleteDataSource(id, orgId) {
    return supabase
        .from('data_sources')
        .delete()
        .eq('org_id', orgId)
        .eq('id', id)
}

export async function listComponents(orgId) {
    return supabase
        .from('components')
        .select('*')
        .eq('org_id', orgId)
        .order('created_at')
}

export async function listTableComponents(orgId) {
    return supabase
        .from('components_tables')
        .select('*')
        .eq('org_id', orgId)
        .order('created_at')
}

export async function listFormComponents(orgId) {
    return supabase
        .from('components_forms')
        .select('*')
        .eq('org_id', orgId)
        .order('created_at')
}

export async function listFormSubmissions(formKey, formVersion, orgId) {
    return supabase
        .from('form_submissions')
        .select(`
            *,
            components_forms!inner (*)
        `)
        .eq('components_forms.key', formKey)
        .eq('components_forms.version', formVersion)
        .eq('components_forms.org_id', orgId)
        .order('created_at', { ascending: false })
}

export async function submitForm(formId, data) {
    return supabase
        .from('form_submissions')
        .insert([
            {
                form_id: formId,
                data: data,
            }
        ]);
}

// pass in formVersion to get a specific version, otherwise get the latest
export async function getFormVersion(formKey, formVersion, orgId) {
    let query = supabase
        .from('components_forms')
        .select('*')
        .eq('key', formKey)
        .eq('org_id', orgId)
        .order('created_at', { ascending: false }) // hack to get the latest version

    if (formVersion) {
        query = query.eq('version', formVersion)
    } else {
        query = query.limit(1)
    }

    return query.single()
}

export async function getFormByID(formID) {
    return supabase
        .from('components_forms')
        .select('*')
        .eq('id', formID)
        .single()
}

export async function createFormVersion(key, version, fields, orgId) {
    return supabase
        .from('components_forms')
        .insert([
            {
                key: key,
                version: version,
                fields: fields,
                org_id: orgId,
            }
        ]);
}

export async function createComponentVersion(key, version, type, data, orgId) {
    return supabase
        .from('components')
        .insert([
            {
                key: key,
                version: version,
                type: type,
                data: data,
                org_id: orgId,
            }
        ]);
}

export async function createTableVersion(key, version, dataSourceID, query, selectedCols, orgId) {
    return supabase
        .from('components_tables')
        .insert([
            {
                key: key,
                version: version,
                data_source_id: dataSourceID,
                query: query,
                selected_cols: selectedCols,
                org_id: orgId,
            }
        ]);
}

export async function getOrgId(userId) {
    return supabase
        .from('orgs_users')
        .select('org_id')
        .eq('user_id', userId)
        .single()
}

async function callServerlessFn(url) {
    const { data, error } = await supabase.auth.getSession();
    if (error) {
        return { error }
    }

    // TODO using a proxy for now... will need to figure out a solution that works with CORS
    // maybe go through a Supabase serverless function?
    const response = await fetch(
        url,
        {
            headers: {
                Authorization: `Bearer ${data.session.access_token}`,
            },
        }
    )
    if (!response.ok) {
        const body = await response.text()
        return { 'error': `Error: ${response.status} ${response.statusText} -- ${body}` }
    }

    const respJson = await response.json()
    return { data: respJson }
}

export async function querySql(dataSourceId, query) {
    return callServerlessFn(`/api/query?dataSourceId=${dataSourceId}&sqlQuery=${query}`)
}

export async function showTables(dataSourceId) {
    return callServerlessFn(`/api/showTables?dataSourceId=${dataSourceId}`)
}

export default {
    listDataSources,
    listComponents,
    listFormComponents,
    listTableComponents,
    listFormSubmissions,
    submitForm,
    getFormVersion,
    getFormByID,
    createFormVersion,
    createComponentVersion,
    createTableVersion,
    upsertDataSource,
    deleteDataSource,
    getOrgId,
    querySql,
    showTables,
}